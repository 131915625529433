import { getRegistrationType, getBusinessStepEvent } from './helpers';
import { ProductNeedsOptions } from './types';

export const step0 = {
  event: 'gaClick',
  eventCategory: 'Registration steps',
  eventAction: 'Select Account',
  eventLabel: 'Step0',
};

export const businessFormSubmission = {
  event: 'CorporateRegoSuccessful',
  RegoSuccessful: 'RegistrationSuccessful',
};

export const businessStep3Submit = {
  event: 'businessStep3Submit',
};

export const productNeedsPageLoad = {
  event: 'gaEvent',
  gaEventCategory: 'Registration steps',
  gaEventAction: 'Business Account',
  gaEventLabel: 'businessStepProductNeeds',
};

export const productNeedsSelection = (
  productNeedsSelection: ProductNeedsOptions
): {} => ({
  event: 'gaEvent',
  gaEventCategory: 'Registration',
  gaEventAction: 'Business',
  gaEventLabel: `${productNeedsSelection}`,
});

export const gcaProductSelectionContinue = {
  event: 'gaEvent',
  gaEventCategory: 'gca.registration',
  gaEventAction: 'business.needs.continue',
};

export const gcaProductSelection = (selection): {} => ({
  event: 'gaEvent',
  gaEventCategory: 'gca.registration',
  gaEventAction: 'business.needs.change',
  gaEventLabel: selection,
});

export const gcaIntroPage = {
  gaEventAction: 'Business Account',
  gaEventCategory: 'Registration steps',
  gaEventLabel: 'GCAIntropage',
};

export const gcaBusinessStep1Submit = {
  event: 'ofx.formSubmit',
  gaEventCategory: 'registration',
  gaEventAction: 'leadgen-form-submit',
};

const events = (event): (() => {}) =>
  ({
    step0: () => step0,
    selectPersonalRego: () => getRegistrationType('Personal'),
    selectBusinessRego: () => getRegistrationType('Business'),
    businessStep1: () => getBusinessStepEvent(1),
    businessStep2: () => getBusinessStepEvent(2),
    businessStep2a: () => getBusinessStepEvent('2a'),
    businessStep3: () => getBusinessStepEvent(3),
    businessFormSubmission: () => businessFormSubmission,
    businessStep3Submit: () => businessStep3Submit,
    productNeedsPageLoad: () => productNeedsPageLoad,
    productNeedsSendMoney: () =>
      productNeedsSelection(ProductNeedsOptions.sendOnly),
    productNeedsSendAndReceiveMoney: () =>
      productNeedsSelection(ProductNeedsOptions.sendAndReceive),
    gcaIntroPage: () => gcaIntroPage,
    gcaBusinessStep1Submit: () => gcaBusinessStep1Submit,
    gcaSelectSendOnly: () => gcaProductSelection('Send Money'),
    gcaSelectSendAndReceiveMoney: () =>
      gcaProductSelection('Send And Receive Money'),
    gcaProductSelectionContinue: () => gcaProductSelectionContinue,
  }[event]);

export default events;
