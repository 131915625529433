import fetch from 'isomorphic-unfetch';
import { getAccessToken } from './auth/auth';
import { sentryLog } from 'src/util/sentryUtil';

interface Options {
  headers: {
    'Content-Type': string;
    Authorization?: string;
  };
}

interface PostOptions extends Options {
  method: 'POST';
  body: string;
}

interface PutOptions extends Options {
  method: 'PUT';
  body: string;
}

const noContent = 204;

export async function get<ResponseType>(
  url: string,
  token?: string
): Promise<ResponseType> {
  try {
    const options: Options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token || getAccessToken()}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status >= 500) {
      throw new Error('HTTP error status 500');
    }

    const body: ResponseType = await res.json();

    return body;
  } catch (error) {
    sentryLog(url, {}, error);
    throw error;
  }
}

export async function post<ResponseType>(
  url: string,
  data,
  token?: string
): Promise<ResponseType> {
  const authToken = token || getAccessToken();
  const hasAuth = authToken ? { Authorization: `Bearer ${authToken}` } : {};

  try {
    const options: PostOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...hasAuth,
      },
      body: JSON.stringify(data),
    };

    const res = await fetch(url, options);

    // Logic to avoid throwing error on POST requests
    // returning 200 with body OK. Could be sorted with 201 on API as well
    let text = await res.text();
    if (res.status === 200) {
      if (text === 'OK') {
        text = '{}';
      }
    }
    const body: ResponseType = res.status === noContent ? {} : JSON.parse(text);

    if (res.status >= 500) sentryLog(url, data, body);

    return body;
  } catch (error) {
    sentryLog(url, data, error);

    throw error;
  }
}

export async function put<ResponseType>(
  url: string,
  data
): Promise<ResponseType> {
  try {
    const options: PutOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(data),
    };

    const res = await fetch(url, options);
    const { status } = res;

    const body: ResponseType = status === noContent ? {} : await res.json();

    if (status >= 400) {
      throw new Error(JSON.stringify(body));
    }

    return body;
  } catch (error) {
    sentryLog(url, data, error);

    throw error;
  }
}
