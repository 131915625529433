export enum RegoTypes {
  personal = 'Personal',
  business = 'Business',
}

export enum Events {
  formSubmit = 'ofx.formSubmit',
  userUpdate = 'ofx.userUpdate',
  fieldError = 'ofx.fieldError',
}

export type UserFields = 'legalEntity' | 'entityType' | 'userId' | 'siteRegion';

export enum AnalyticEventTypes {
  step0 = 'step0',
  selectPersonalRego = 'selectPersonalRego',
  selectBusinessRego = 'selectBusinessRego',
  businessStep1 = 'businessStep1',
  businessStep2 = 'businessStep2',
  businessStep2a = 'businessStep2a',
  businessStep3 = 'businessStep3',
  businessFormSubmission = 'businessFormSubmission',
  businessStep3Submit = 'businessStep3Submit',
  productNeedsPageLoad = 'productNeedsPageLoad',
  productNeedsSendMoney = 'productNeedsSendMoney',
  productNeedsSendAndReceiveMoney = 'productNeedsSendAndReceiveMoney',
  gcaIntroPage = 'gcaIntroPage',
  gcaBusinessStep1Submit = 'gcaBusinessStep1Submit',
  gcaSelectSendOnly = 'gcaSelectSendOnly',
  gcaSelectSendAndReceiveMoney = 'gcaSelectSendAndReceiveMoney',
  gcaProductSelectionContinue = 'gcaProductSelectionContinue',
}

export enum CustomerType {
  businessOLS = 'BusinessOLS',
  business = 'Business',
  businessGCA4B = 'BusinessGCA4B',
}

export enum ProductNeedsOptions {
  sendAndReceive = 'ProductNeedsSend&ReceiveMoney',
  sendOnly = 'ProductNeedsSendMoney',
}
