import React from 'react';

// This component is only required for unauthenticated pages
// which need access to publicRuntimeConfig vars.
// ---------------------------------------------------------------------- //
// The purpose of this HOC is to allow pages to use runtime config variables.
// Next.js gives access to publicRuntimConfig vars when developing locally,
// but not in production. This can be bypassed by calling getInitialProps().

export function WithoutAuth(
  WrappedComponent: React.ComponentType
): React.ComponentType {
  return class Unauthenticated extends React.Component<{}> {
    public static getInitialProps = () => {
      return {};
    };

    public render(): React.ReactElement {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default WithoutAuth;
