import { CustomerType } from './types';

export const getRegistrationType = (regoType: 'Business' | 'Personal'): {} => ({
  event: 'gaEvent',
  gaEventCategory: 'Register',
  gaEventAction: 'Account Type',
  gaEventLabel: `${regoType} Account`,
  registrationType: `${regoType} Account`,
});

export const getBusinessStepEvent = (step): {} => ({
  event: 'registrationStep',
  registrationStep: `businessStep${step}`,
  registrationType: 'Business Account',
});

export const getCustomerType = (isGcaAccount, gcaData): string => {
  const { payerLocations, marketplaces, paymentGateways } = gcaData;
  const isGca4Business =
    payerLocations.length > 0 &&
    marketplaces.length === 0 &&
    paymentGateways.length === 0;

  let customerType: string;
  switch (true) {
    // only TODO if everything else is empty
    case isGca4Business:
      customerType = CustomerType.businessGCA4B;
      break;
    case isGcaAccount:
      customerType = CustomerType.businessOLS;
      break;
    default:
      customerType = CustomerType.business;
  }
  return customerType;
};
