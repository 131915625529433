import { NextRouter } from 'next/router';

export function redirect(
  url: string,
  router: NextRouter,
  as?: string,
  options?: object
): void {
  void router.push(url, as, options);
}

export function getAsPath(router: NextRouter): string {
  return router.asPath;
}
