import cookies from 'js-cookie';
import sha256 from 'crypto-js/sha256';
import { sentryLog } from 'src/util/sentryUtil';
import { Events, UserFields, AnalyticEventTypes } from './types';
import getEventData from './eventsData';

const canPushToGtm = (): boolean =>
  !!(typeof window !== 'undefined' && window.dataLayer);

export const notifyFieldError = ({ field, value }): void => {
  canPushToGtm() &&
    window.dataLayer.push({
      event: Events.fieldError,
      fieldName: field,
      fieldValue: value,
    });
};

// We are adding this custom trigger to notify GTM the result of a form submission
export const notifyFormSubmitEvent = (
  label: string,
  success: boolean
): void => {
  canPushToGtm() &&
    window.dataLayer.push({
      event: Events.formSubmit,
      label: label,
      formSubmitSuccess: success,
    });
};

export const pushAnalyticsEvent = (
  event: AnalyticEventTypes,
  _siteRegion: string = cookies.get().region,
  customDimensions?: {}
): void => {
  const siteRegion = _siteRegion ? _siteRegion.split('-')[1] : undefined;
  const data = {
    ...getEventData(event)(),
    siteRegion,
    ...customDimensions,
  };

  try {
    if (canPushToGtm()) {
      // @ts-ignore
      window.dataLayer.push(data);
    }
  } catch (err) {
    sentryLog('DataLayer Error', data, `DataLayer Error: ${err}`);
  }
};

export const pushUserInfo = (userFields: {
  [key in UserFields]?: string;
}): void => {
  canPushToGtm() &&
    window.dataLayer.push({
      event: Events.userUpdate,
      ...userFields,
    });
};

export const push = (
  event: string,
  fields: {
    [key in string]?: string;
  }
): void => {
  canPushToGtm() &&
    window.dataLayer.push({
      event,
      ...fields,
    });
};

export const hashToSha256WithoutSalt = (value: string): string => {
  return sha256(value.trim().toLowerCase()).toString();
};

export const getEmailSld = (email: string): string => {
  return email.split('@')[1]?.split('.')[0]?.toLowerCase() || '';
};
